import React, { useState, useContext } from "react"
import { FirebaseContext } from "../../firebase"
import { Link, navigate } from "gatsby"
import { useMicroCopyFi } from "../../hooks/microCopy"
import GetMicroCopy from "../../shared/getMicroCopy"

import Box from "@material-ui/core/Box"
import * as styles from "./form.module.scss"

import { checkValidity } from "../../shared/utility"

const RegisterForm = () => {
  const { firebase } = useContext(FirebaseContext)
  const [errorMessage, setErrorMessage] = useState("")
  const [registered, setRegistered] = useState(false)

  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    termsAcceptance: false,
  })

  const microCopyTexts = useMicroCopyFi
  // UI texts
  const labelRegisterTitle = GetMicroCopy(
    microCopyTexts,
    "lomakeRekisteröidyOtsikko"
  )
  const labelLogin = GetMicroCopy(microCopyTexts, "lomakeKirjaud")
  const labelEmail = GetMicroCopy(microCopyTexts, "lomakeSähköposti")
  const labelPassword = GetMicroCopy(microCopyTexts, "lomakeSalasana")
  const labelFirstname = GetMicroCopy(microCopyTexts, "lomakeEtunimi")
  const labelLastName = GetMicroCopy(microCopyTexts, "lomakeSukunimi")
  const labelPasswordConfirm = GetMicroCopy(
    microCopyTexts,
    "lomakeSalasanaVahvista"
  )
  const labelAcceptTerms = GetMicroCopy(
    microCopyTexts,
    "lomakeHyväksyKäsittely"
  )
  const labelAlreadyAccount = GetMicroCopy(microCopyTexts, "lomakeOnkoJoTili")
  const labelAccountSuccesfull = GetMicroCopy(microCopyTexts, "lomakeTiliLuotu")
  const labelErrorPassword = GetMicroCopy(microCopyTexts, "lomakeVirheSalasana")
  const labelErrorFirstName = GetMicroCopy(microCopyTexts, "lomakeVirheEtunimi")
  const labelErrorLastName = GetMicroCopy(microCopyTexts, "lomakeVirheSukunimi")
  const labelErrorPasswordLength = GetMicroCopy(
    microCopyTexts,
    "lomakeVirheSalasananPituus"
  )
  const labelErrorEmailInUse = GetMicroCopy(
    microCopyTexts,
    "lomakeVirheSähköpostiKäytössä"
  )
  const labelErrorPasswordMatch = GetMicroCopy(
    microCopyTexts,
    "lomakeVirheSalasanaVahvista"
  )
  // end UI texts

  function handleInputChange(e) {
    e.persist()
    setErrorMessage("")
    setFormValues(currentValues => ({
      ...currentValues,
      [e.target.name]:
        e.target.name === "termsAcceptance" ? e.target.checked : e.target.value,
    }))

    switch (e.target.name) {
      case "firstName":
        if (!checkValidity(e.target.value, { required: true })) {
          setErrorMessage(labelErrorFirstName)
        } else {
          setErrorMessage("")
        }
        break
      case "lastName":
        if (!checkValidity(e.target.value, { required: true })) {
          setErrorMessage(labelErrorLastName)
        } else {
          setErrorMessage("")
        }
        break
      case "email":
        if (!checkValidity(e.target.value, { required: true, isEmail: true })) {
          setErrorMessage(labelErrorPassword)
        } else {
          setErrorMessage("")
        }
        break
      case "password":
        if (!checkValidity(e.target.value, { required: true, minLength: 8 })) {
          setErrorMessage(labelErrorPasswordLength)
        } else {
          setErrorMessage("")
        }
        break
      default:
        break
    }
  }

  function addUser({
    email,
    first_name,
    last_name,
    terms_and_conditions_acceptance,
  }) {
    return firebase.addUser({
      email: email,
      first_name: first_name,
      last_name: last_name,
      terms_and_conditions_acceptance: terms_and_conditions_acceptance,
    })
  }

  function handleSubmit(e) {
    e.preventDefault()
    if (formValues.password === formValues.confirmPassword) {
      firebase
        .register({
          email: formValues.email,
          password: formValues.password,
        })
        .then(ref => {
          addUser({
            email: formValues.email,
            first_name: formValues.firstName,
            last_name: formValues.lastName,
            terms_and_conditions_acceptance: formValues.termsAcceptance,
          }).then(ref => {
            setRegistered(true)
          })
        })
        .catch(err => {
          setErrorMessage(labelErrorEmailInUse)
        })
    } else {
      setErrorMessage(labelErrorPasswordMatch)
    }
  }

  return (
    <Box
      component="section"
      className={styles.form}
      style={{ padding: "0 20px", maxWidth: "1240px", margin: "85px auto" }}
    >
      {!!registered && (
        <Box component="div" className={styles.formContainer}>
          <p>
            {labelAccountSuccesfull}
            <Link to={"/login"}>{labelLogin}</Link>
          </p>
        </Box>
      )}
      {!registered && (
        <Box component="div" className={styles.formContainer}>
          <header>
            <h1>{labelRegisterTitle}</h1>
          </header>
          <form onSubmit={handleSubmit}>
            {!!errorMessage && <p>{errorMessage}</p>}

            <div className={styles.formRow}>
              <label>
                {labelFirstname} <span>*</span>
              </label>
              <input
                onChange={handleInputChange}
                value={formValues.firstName}
                type="text"
                required
                name="firstName"
              />
            </div>

            <div className={styles.formRow}>
              <label>
                {labelLastName} <span>*</span>
              </label>
              <input
                onChange={handleInputChange}
                value={formValues.lastName}
                type="text"
                required
                name="lastName"
              />
            </div>

            <div className={styles.formRow}>
              <label>
                {labelEmail} <span>*</span>
              </label>
              <input
                onChange={handleInputChange}
                value={formValues.email}
                type="email"
                required
                name="email"
              />
            </div>

            <div className={styles.formRow}>
              <label>
                {labelPassword} <span>*</span>
              </label>
              <input
                onChange={handleInputChange}
                value={formValues.password}
                type="password"
                required
                minLength={8}
                name="password"
              />
            </div>

            <div className={styles.formRow}>
              <label>
                {labelPasswordConfirm} <span>*</span>
              </label>
              <input
                onChange={handleInputChange}
                value={formValues.confirmPassword}
                type="password"
                required
                name="confirmPassword"
              />
            </div>

            <div className={styles.formRow}>
              <label>
                {labelAcceptTerms}
                <input
                  onChange={handleInputChange}
                  value={formValues.termsAcceptance}
                  type="checkbox"
                  name="termsAcceptance"
                />
              </label>
            </div>

            <button type="submit">{labelRegisterTitle}</button>
          </form>

          <footer>
            <p>
              {labelAlreadyAccount} <Link to={"/login"}>{labelLogin}</Link>
            </p>
          </footer>
        </Box>
      )}
    </Box>
  )
}

export default RegisterForm
