import React from "react";
import RegisterForm from "../components/forms/registerForm";
import LayoutController from "../components/layoutController"

const Register = () => {
  return (
    <LayoutController language="fi"> 
    <RegisterForm />
    </LayoutController>
  )
}

export default Register;